<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-unused-vars -->
<template>
  <Layout>

    <a @click="this.$router.go(-1)">
      <font-awesome-icon icon="arrow-left" /> Back
    </a>
    <div class="card">
      <div class="card-header">
        Device {{ device.deviceid }} <i>  (last seen: {{ lastseen }}) </i>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="submitForm">
          <div class="">
            <label for="deviceid" class="col-sm-3 uk-form-label">Device Id (*this is automatically added)</label>
            <div class="col-sm-9">
              {{ device.deviceid }}
            </div>
          </div>
          <div class="">
            <label for="deviceid" class="col-sm-3 uk-form-label">Device name</label>
            <div class="col-sm-9">
              <input type="text" class="uk-input" id="staticEmail" v-model="form.devicename">
            </div>
          </div>
          <div class="">
            <label for="deviceid" class="col-sm-3 uk-form-label">Customer Device name</label>
            <div class="col-sm-9">
              <input type="text" class="uk-input" id="staticEmail" v-model="form.customerdevicename">
            </div>
          </div>
          <div class="col-sm-9 uk-margin-top">
            <button class="uk-button uk-button-primary">Save</button>
          </div>
          <div class="form-group">
            <div v-if="messagefault" class="alert alert-danger" role="alert">
              {{ messagefault }}
            </div>
            <div v-if="messageok" class="alert alert-success" role="alert">
              {{ messageok }}
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="uk-margin"></div>
    <div id="app">
      <ul class="nav nav-tabs nav-justified">
        <li class="nav-item">
          <a class="nav-link" @click.prevent="setActive('dashboard')" :class="{ active: isActive('dashboard') }"
            href="#dashboard">Dashboard</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="setActive('status')" :class="{ active: isActive('status') }"
            href="#status">Last Day Status </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="setActive('telemetry')" :class="{ active: isActive('telemetry') }"
            href="#telemetry">Last Day Telemetry</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="setActive('graph')" :class="{ active: isActive('graph') }"
            href="#graph">30 Day Overview</a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade" :class="{ 'active show': isActive('dashboard') }" id="dashboard">
          <div class="card">
            <div class="card-header">
              Device Statistics
            </div>
            <div class="card-body">
              <div class="uk-child-width-1-3@m uk-grid-small uk-grid-match" uk-grid>
                <div>
                  <div class="uk-card uk-card-default uk-card-body">
                    <h3 class="uk-card-title">Status</h3>
                    <p>Current device status</p>
                    <p class="uk-heading-small">{{ device.status }}</p>
                  </div>
                </div>
                <div>
                  <div class="uk-card uk-card-default uk-card-body">
                    <h3 class="uk-card-title">Total Shots</h3>
                    <p>Lifetime shots made</p>
                    <p class="uk-heading-small">{{ device.counter }}</p>
                  </div>
                </div>
                <div>
                  <div class="uk-card uk-card-default uk-card-body">
                    <h3 class="uk-card-title">Service Counter</h3>
                    <p>Shots since last service</p>
                    <p class="uk-heading-small">{{ device.servicecounter }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" :class="{ 'active show': isActive('status') }" id="status">

          <div class="card">
            <div class="card-header">
              Last Messages
            </div>
            <div class="card-body">
              <div class="container">
                <div class="row">
                  <div class="col-sm">
                    From date:
                    <datepicker v-model="startdatestatus" inputFormat="dd-MM-yyyy" />
                  </div>
                  <div class="col-sm">
                    To Date:
                    <datepicker v-model="enddatestatus" inputFormat="dd-MM-yyyy" />
                  </div>
                  <div class="col-sm">
                    <div class="col-sm-9 uk-margin-top">
                      <button v-on:click="devicestatusRerender()" class="uk-button uk-button-primary">Query</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-margin"></div>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Device Id</th>
                    <th scope="col">Status</th>
                    <th scope="col">Start</th>
                    <th scope="col">End</th>
                    <th scope="col">Duration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr scope="row" v-for="devicestatus in devicestatus" :key="devicestatus.id">
                    <td>{{ devicestatus.deviceid }}</td>
                    <td>{{ devicestatus.status }}</td>
                    <td>{{ format_date(devicestatus.start) }}</td>
                    <td>{{ format_date(devicestatus.end) }}</td>
                    <td>{{ timebetween(devicestatus.end, devicestatus.start) }}</td>
                    <!-- and so on -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" :class="{ 'active show': isActive('telemetry') }" id="telemetry">
          <div class="card">
            <div class="card-header">
              Last Messages
            </div>
            <div class="card-body">
              <div class="container">
                <div class="row">
                  <div class="col-sm">
                    From date:
                    <datepicker v-model="startdatetelemetry" inputFormat="dd-MM-yyyy" />
                  </div>
                  <div class="col-sm">
                    To Date:
                    <datepicker v-model="enddatetelemetry" inputFormat="dd-MM-yyyy" />
                  </div>
                  <div class="col-sm">
                    <div class="col-sm-9 uk-margin-top">
                      <button v-on:click="devicetelemetryRerender()" class="uk-button uk-button-primary">Query</button>

                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-margin"></div>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Device Id</th>
                    <th scope="col">Type</th>
                    <th scope="col">Count</th>
                    <th scope="col">Created</th>
                  </tr>
                </thead>
                <tbody>
                  <tr scope="row" v-for="devicetelemetry in devicetelemetry" :key="devicetelemetry.id">
                    <td>{{ devicetelemetry.deviceid }}</td>
                    <td>{{ devicetelemetry.type }}</td>
                    <td>{{ devicetelemetry.count }}</td>
                    <td>{{ format_date(devicetelemetry.created) }}</td>
                    <!-- and so on -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" :class="{ 'active show': isActive('graph') }" id="graph">
          <div class="card">
            <div class="card-header">
              Last 30 Days
              <a href="#" v-on:click="devicetelemetryDayRerender()"
                class="badge badge-secondary float-right">Refresh</a>
            </div>
            <div class="card-body">
              <div id="divCanvas">
                <Bar id="canvas" v-if="loaded" :chart-options="chartOptions" :chart-data="{
                  labels: this.labels,
                  datasets: [{
                    label: 'Count',
                    backgroundColor: '#4eb1ed', data: this.data
                  }]
                }" :chart-id="chartId" :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses"
                  :styles="styles" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="uk-margin"></div>

    <div class="uk-margin"></div>

  </Layout>
</template>

<script>
import Layout from '../layouts/private.vue'
import DeviceService from "../services/device.service";
import CustomerService from "../services/customer.service";
import EventBus from "../common/EventBus";
import Datepicker from 'vue3-datepicker';
import moment from 'moment';
import {DateTime} from 'luxon';
import { Bar } from 'vue-chartjs';

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "Device View",
  components: { Layout, Bar, Datepicker },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },

    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      content: "Device",
      device: "",
      devicetelemetry: "",
      devicestatus: "",
      devicetelemetryday: [],
      messageok: "",
      startdatestatus: new Date(),
      enddatestatus: new Date(),
      startdatetelemetry: new Date(),
      enddatetelemetry: new Date(),
      messagefault: "",
      customer: "",
      labels: [],
      lastseen: '',
      messagefromdate: new Date(),
      messagetodate: new Date(),

      data: [],
      activeItem: 'dashboard',
      form:
      {
        deviceid: '',
        devicename: '',
        customerdevicename: '',
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      loaded: false,
    };
  },
  created() {
    this.devicerender();
  //  this.devicetelemetryRerender();
  //  this.devicestatusRerender();
  //  this.devicetelemetryDayRerender();
    this.timer = setInterval(this.deviceRerender, 3000);

  },
  mounted() {
    CustomerService.getCustomers().then(
      (response) => {
        this.customers = response.data.results;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      }
    );

  },
  methods: {
    devicerender() {
      DeviceService.getDevice(this.$route.params.id).then(
        (response) => {
          this.device = response.data;
          this.form.deviceid = response.data.deviceid;
          this.form.devicename = response.data.devicename;
          this.lastseen = DateTime.fromISO(response.data.lastseen).toRelative();
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    deviceRerender() {
      DeviceService.getDevice(this.$route.params.id).then(
        (response) => {
          this.device = response.data;
          this.lastseen = DateTime.fromISO(response.data.lastseen).toRelative();
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    devicetelemetryRerender() {
      DeviceService.getDeviceTelemetry(this.$route.params.id, moment(this.startdatetelemetry).toISOString(), moment(this.enddatetelemetry).toISOString()).then(
        (response) => {
          this.devicetelemetry = response.data;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    devicestatusRerender() {
      DeviceService.getDeviceStatus(this.$route.params.id, moment(this.startdatestatus).toISOString(), moment(this.enddatestatus).toISOString()).then(
        (response) => {
          this.devicestatus = response.data;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    devicetelemetryDayRerender() {
      this.loaded = false;
      DeviceService.getDeviceTelemetryDay(this.$route.params.id).then(
        (response) => {
          this.devicetelemetryday = response.data;
          const labels2 = [];
          const data2 = [];
          const date = moment(new Date()).add(-29, 'day');
          let endDate = moment(new Date());
          do {
            let dateStr = date.format("YYYY-MM-DD");
            labels2.push(dateStr);
            if (response.data.find(x => x._id === dateStr) === undefined) {
              data2.push(0);
            } else { data2.push(response.data.find(x => x._id === dateStr).count) }
            //  if (this.devicetelemetryday.hasOwn_(dateStr))
            //  data2.push(this.devicetelemetryday[dateStr]);
            //  else

            date.add(1, 'day');
          } while (date.isBefore(endDate));

          this.labels = labels2;
          this.data = data2;
          this.loaded = true;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD-MM-YYYY HH:mm:ss')
      }
    },
    timebetween(end, start) {
      var dur = moment.utc(moment.duration(moment(end).diff(start)).as('milliseconds')).format('HH:mm:ss')

      return (dur);


    },

    submitForm() {
      DeviceService.updateDevice(this.$route.params.id, this.form).then(
        (response) => {
          this.messageok = "Saved succesfully";
          console.log(response)
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.messagefault = error.message;
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    isActive(menuItem) {
      return this.activeItem === menuItem
    },
    setActive(menuItem) {
      this.activeItem = menuItem
    }
  },

  beforeUnmount() {
    this.cancelAutoUpdate();
  },

};

</script>

<style>
#canvas {
  width: 100%;
  height: 300px;
}
</style>
